import { useState } from "react";
import { colors, spacings } from "../../assets/themes";
import { Badge, Block, Button, Icon, Input, Tabs } from "../../components";
import { BUTTON, INPUT } from "../../components/Styles/variants";
import { useBreakpoints } from "../../modules/hooks";
import polyglot from "../../utils/polyglot";
import DATA from "./data";
import { getTotalInventoryItems } from "./utils";
import CubeSchema from "./CubeSchema";
import NewObjectModal from "./NewObjectModal";
import InventoryModal from "./InventoryModal";

const MovingHeader = ({
  onSearch,
  term,
  inventory,
  onClear,
  onChange,
  activeTabIndex,
  onTabChange,
}) => {
  const breakpoint = useBreakpoints();
  const [newObjectModalIsOpen, setNewObjectModalIsOpen] = useState(false);
  const [inventoryModalIsOpen, setInventoryModalIsOpen] = useState(false);

  const handleCreateObject = ({ width, height, long, amount }) => {
    onChange(`custom-${long}x${width}x${height}`, amount, {
      label: `${
        polyglot
          .toDistance(long, {
            small: true,
          })
          .split(",")[0]
      }x${polyglot.toDistance(width, { small: true }).split(",")[0]}x${
        polyglot.toDistance(height, { small: true }).split(",")[0]
      }`,
      image: () => (
        <CubeSchema
          width={width}
          height={height}
          long={long}
          strokeWidth={35}
        />
      ),
      volume: (long / 100) * (width / 100) * (height / 100),
    });
  };

  return (
    <>
      <Block
        display="flex"
        flexDirection="row"
        alignItems="center"
        paddingX={spacings.m}
        paddingTop={spacings.m}
      >
        <Input
          type="search"
          value={term}
          onChange={onSearch}
          placeholder={polyglot.t("common.search_element")}
          size={INPUT.SIZE.SMALL}
        />

        <Block display="flex" marginTop={0}>
          <Block marginLeft={spacings.s}>
            {breakpoint.get({
              xs: (
                <Button.Small
                  kind={BUTTON.KIND.SECONDARY}
                  shape={BUTTON.SHAPE.CIRCLE}
                  onClick={() => setNewObjectModalIsOpen(true)}
                >
                  <Icon.Medium name="plus-circle" />
                </Button.Small>
              ),
              md: (
                <Button.Small
                  kind={BUTTON.KIND.SECONDARY}
                  shape={BUTTON.SHAPE.PILL}
                  onClick={() => setNewObjectModalIsOpen(true)}
                  LeftComponent={() => <Icon.Medium name="plus-circle" />}
                >
                  {polyglot.t("common.add")}
                </Button.Small>
              ),
            })}
          </Block>
          <Block display={{ xs: "block", md: "none" }}>
            <Button.Small
              kind={BUTTON.KIND.SECONDARY}
              css={`
                margin-left: ${spacings.xs};
                overflow: visible;
              `}
              accentColor={BUTTON.ACCENT_COLOR.BODY}
              shape={BUTTON.SHAPE.CIRCLE}
              onClick={() => setInventoryModalIsOpen(true)}
            >
              <Badge count={getTotalInventoryItems(inventory)}>
                <Icon.Large name="cart" />
              </Badge>
            </Button.Small>
          </Block>
        </Block>
      </Block>
      {term?.length === 0 && (
        <Block marginTop={spacings.m} overflowX="auto">
          <Tabs value={activeTabIndex} onChange={onTabChange}>
            {Object.keys(DATA).map((key, i) => (
              <Tabs.Tab value={i} key={`tab-customer-admin-${i}`}>
                {key}
              </Tabs.Tab>
            ))}
          </Tabs>
        </Block>
      )}

      <NewObjectModal
        isOpen={newObjectModalIsOpen}
        onSubmit={handleCreateObject}
        onClose={() => setNewObjectModalIsOpen(false)}
      />
      <InventoryModal
        isOpen={inventoryModalIsOpen}
        onClose={() => setInventoryModalIsOpen(false)}
        items={inventory}
        onClear={onClear}
        onChange={onChange}
      />
    </>
  );
};
export default MovingHeader;
