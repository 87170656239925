import { useFormikContext } from "formik";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { colors } from "../../../assets/themes";
import { Body14 } from "../../../components";
import { useGoogleAnalytics } from "../../../modules/hooks";
import { getApplePayButtonAvailableEvent } from "../../../utils/analytics-events";
import polyglot from "../../../utils/polyglot";
import CreditCardAccordion from "./CreditCardAccordion";
import ExternalMethod from "./ExternalMethod";
import { APPLE_PAY, GOOGLE_PAY, IDEAL, PAYPAL, TWINT } from "./constants";
import { browserInfo, getPaymentMethodConfig } from "./utils";

const PaymentMethodsUpdated = React.forwardRef(
  (
    { methods: _methods, onChange, needCCCheck, disabled, withGutters },
    ref
  ) => {
    const creditCardRef = useRef();
    const { sendEvent } = useGoogleAnalytics();
    const isApplePayAvailable = !!window.ApplePaySession;
    const { validateForm, setValues, values, setFieldValue } =
      useFormikContext();
    let methods = _methods;

    const [active, setActive] = useState(
      methods.length === 1 &&
        (values.payment_method
          ? !getPaymentMethodConfig(values.payment_method)?.external
          : true)
    );

    methods = _methods
      .filter((m) => (isApplePayAvailable ? true : m !== APPLE_PAY))
      .map((m) => m.toLowerCase());

    useEffect(() => {
      setFieldValue("browserInfo", browserInfo);
    }, []);

    useEffect(() => {
      if (methods.includes(APPLE_PAY)) {
        sendEvent(getApplePayButtonAvailableEvent());
      }
    }, [methods]);

    useImperativeHandle(
      ref,
      () => ({
        ...creditCardRef.current,
      }),
      []
    );

    const handleChange = ({
      payment_method,
      token,
      card_number,
      card_type,
    }) => {
      let arr;
      if (card_number) {
        arr = { ...arr, card_number };
      }
      if (card_type) {
        arr = { ...arr, card_type };
      }
      if (token) {
        arr = { ...arr, token };
      }
      if (payment_method) {
        arr = { ...arr, payment_method };
      }
      setValues((s) => ({ ...s, ...arr }), false);
      setTimeout(() => {
        validateForm();
      }, 0);
      if (onChange) onChange(arr);
    };

    return (
      <>
        <CreditCardAccordion
          methods={methods}
          needCCCheck={needCCCheck}
          onChange={handleChange}
          disabled={disabled}
          active={active}
          ref={creditCardRef}
          setActive={setActive}
          withGutters={withGutters}
        />
        {methods.includes(IDEAL) && (
          <ExternalMethod
            name={IDEAL}
            onChange={(v) => {
              handleChange(v);
              setActive(false);
            }}
            disabled={disabled}
            withGutters={withGutters}
          />
        )}
        {methods.includes(TWINT) && (
          <ExternalMethod
            name={TWINT}
            onChange={(v) => {
              handleChange(v);
              setActive(false);
            }}
            disabled={disabled}
            withGutters={withGutters}
          />
        )}
        {methods.includes(APPLE_PAY) && (
          <ExternalMethod
            name={APPLE_PAY}
            onChange={(v) => {
              handleChange(v);
              setActive(false);
            }}
            disabled={disabled}
            withGutters={withGutters}
          />
        )}
        {methods.includes(GOOGLE_PAY) && (
          <ExternalMethod
            name={GOOGLE_PAY}
            onChange={(v) => {
              handleChange(v);
              setActive(false);
            }}
            disabled={disabled}
            withGutters={withGutters}
          />
        )}
        {methods.includes(PAYPAL) && (
          <ExternalMethod
            name={PAYPAL}
            onChange={(v) => {
              handleChange(v);
              setActive(false);
            }}
            disabled={disabled}
            withGutters={withGutters}
          >
            <Body14 color={colors.muted}>
              {polyglot.t("payment_methods.paypal_4x_description")}
            </Body14>
          </ExternalMethod>
        )}
      </>
    );
  }
);

export default PaymentMethodsUpdated;
