import { artwork } from "@/assets/categories";
import EventRcSrc from "@/assets/images/banners/valentinesday25-rc.svg";
import { colors, spacings } from "@/assets/themes";
import { Banner, Block, Body16, List } from "@/components";
import { BANNER } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";
import polyglot from "@/utils/polyglot";
import CategoriesVertical from "./Blocks/CategoriesVertical";
import JobbersGrid from "./Blocks/JobbersGrid";

const getMockedCategories = () => ({
  categories: [
    {
      name: polyglot.t("valentinesday25.cleaning"),
      category_id: 4001,
      src: artwork[4001],
      url_params: {
        special_event: "valentinesday",
        utm_campaign: "valentinesday-2025",
        utm_content: "menage-domicile",
        utm_medium: "website",
        utm_source: "yoojo",
      },
    },
    {
      name: polyglot.t("valentinesday25.dog_sitting"),
      category_id: 5006,
      src: artwork[5006],
      url_params: {
        special_event: "valentinesday",
        utm_campaign: "valentinesday-2025",
        utm_content: "garde-chien",
        utm_medium: "website",
        utm_source: "yoojo",
      },
    },
    {
      name: polyglot.t("valentinesday25.small_repairs"),
      category_id: 1037,
      src: artwork[1037],
      url_params: {
        special_event: "valentinesday",
        utm_campaign: "valentinesday-2025",
        utm_content: "petite-reparations",
        utm_medium: "website",
        utm_source: "yoojo",
      },
    },
    {
      name: polyglot.t("valentinesday25.waiter"),
      category_id: 7021,
      src: artwork[7021],
      url_params: {
        special_event: "valentinesday",
        utm_campaign: "valentinesday-2025",
        utm_content: "serveur",
        utm_medium: "website",
        utm_source: "yoojo",
      },
    },
    {
      name: polyglot.t("valentinesday25.cook"),
      category_id: 7020,
      src: artwork[7020],
      url_params: {
        special_event: "valentinesday",
        utm_campaign: "valentinesday-2025",
        utm_content: "preparer-repas",
        utm_medium: "website",
        utm_source: "yoojo",
      },
    },
  ],
});

const HomeEventTab = () => {
  const data = getMockedCategories();
  const breakpoints = useBreakpoints();

  const isBannerVisible = () =>
    // const today = moment();
    // const endDate = moment("2024-12-31", "YYYY-MM-DD");
    // return today.isSameOrBefore(endDate);
    true;

  return (
    <Block>
      <List.Header>
        <span>{polyglot.t("valentinesday25.title")}</span>
        <Body16
          css={`
            font-weight: 400;
          `}
        >
          {polyglot.t("valentinesday25.subtitle")}
        </Body16>
      </List.Header>
      {isBannerVisible() && (
        <>
          <Banner
            accent="light"
            title={polyglot.t("valentinesday25.banner_title", {
              amount: polyglot.toSmartCurrency(5, { noDecimal: true }),
            })}
            subtitle={`${polyglot.t("valentinesday25.banner_subtitle")}`}
            backgroundColor={colors.danger50}
            rightBackgroundImage={EventRcSrc}
            size={breakpoints.get({
              xs: BANNER.SIZE.MEDIUM,
              xl: BANNER.SIZE.LARGE,
            })}
          />
          {/* <Block marginTop={spacings.s}>
            <Body14
              color={colors.muted}
              strong
              css={`
                font-weight: 400;
              `}
            >
              {polyglot.t("christmas24.starbucks.subtitle_star")}
            </Body14>
          </Block> */}
        </>
      )}
      <Block marginTop={spacings.ml}>
        {data.categories?.length > 0 && (
          <>
            <CategoriesVertical
              block={{ category_id: null, data: data.categories }}
            />
          </>
        )}
      </Block>
      {data.jobbers?.length > 0 && (
        <>
          <List.Header>{data.jobbers_title}</List.Header>
          <JobbersGrid block={{ data: data.jobbers }} hideJobbersSeeMore />
        </>
      )}
    </Block>
  );
};

export default HomeEventTab;
