import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { borderWidth, colors, shadows, spacings } from "@/assets/themes";
import { Block, ConditionnalWrapper, Container } from "@/components";
import { cssVarToNumber } from "@/components/Styles/Helper";
import { useBreakpoints, useIntersectionObserver } from "@/modules/hooks";
import {
  useGetHome,
  useGetHomeCategory,
} from "@/modules/routes/dashboard-routes";
import HomeBlocks from "./home/HomeBlocks";
import HomeCategory from "./home/HomeCategory";
import HomeHeader from "./home/HomeHeader";
import HomeTabs from "./home/HomeTabs";
import DiscoverSkeleton from "./skeletons/DiscoverSkeleton";
import HomeEventTab from "./home/HomeEventTab";

const Home = () => {
  const [isStickyFloating, setIsStickyFloating] = useState(null);
  const { isLoading, data } = useGetHome();
  const homeData = useGetHomeCategory();
  const anchorRef = useRef();
  const location = useLocation();

  useIntersectionObserver({
    target: anchorRef,
    rootMargin: `-${cssVarToNumber("var(--nav-height)")}px`,
    onObserver: (entry) => {
      setIsStickyFloating(!entry.isIntersecting);
    },
  });

  const breakpoints = useBreakpoints();

  const getCategoryFromSearch = () => {
    const params = new URLSearchParams(location.search);
    return params.get("category");
  };

  const renderContent = () => {
    const currentCategory = getCategoryFromSearch();

    if (currentCategory === "99") {
      return <HomeEventTab category={currentCategory} />;
    }

    if (currentCategory) {
      return <HomeCategory />;
    }

    return (
      <>
        {!isLoading && !homeData.isLoading ? (
          <HomeBlocks
            blocs={homeData?.data?.blocs}
            active_jobs={homeData?.data?.active_jobs}
            reminders={homeData?.data?.reminders}
            event_banners={homeData?.data?.event_banners}
          />
        ) : (
          <DiscoverSkeleton />
        )}
      </>
    );
  };

  return (
    <Block marginY={{ xs: spacings.m, md: spacings.l }} position="relative">
      <div>
        <Container.Large>
          <Block marginBottom={{ xs: spacings.s, md: spacings.m }}>
            <HomeHeader data={data} isLoading={isLoading} />
          </Block>
        </Container.Large>
        <div ref={anchorRef} />
        <Block
          css={`
            border-bottom: solid ${borderWidth.m} ${colors.border};
            position: sticky;
            z-index: 1;
            background-color: ${colors.background};
            top: var(--nav-height);
            box-shadow: ${isStickyFloating ? shadows.s : "none"};
          `}
        >
          <ConditionnalWrapper
            condition={breakpoints.get({ xs: false, md: true })}
            wrapper={(children) => (
              <Container.Large>{children}</Container.Large>
            )}
          >
            <Block marginBottom={`calc(${borderWidth.m} * -1)`}>
              <HomeTabs />
            </Block>
          </ConditionnalWrapper>
        </Block>

        <Container.Large>{renderContent()}</Container.Large>
      </div>
    </Block>
  );
};

export default Home;
