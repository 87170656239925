import { useFormik } from "formik";
import { colors, radius, spacings } from "../../assets/themes";
import {
  Block,
  Button,
  FormControl,
  H3,
  Modal,
  Slider,
} from "../../components";
import { BUTTON } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";
import CubeSchema from "./CubeSchema";

const MIN = 5;
const MAX = 500;

const NewObjectModal = ({ onClose, isOpen, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      width: MAX / 2,
      height: MAX / 2,
      long: MAX / 2,
      amount: 1,
    },
    onSubmit: (v) => {
      onSubmit(v);
      formik.resetForm();
      onClose();
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Modal.Small onClose={onClose} isOpen={isOpen} fullScreenOnMobile>
      <form
        onSubmit={formik.handleSubmit}
        css={`
          position: relative;
        `}
      >
        <Modal.Item.Header
          onClose={onClose}
          css={`
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
          `}
        />
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginBottom={spacings.m}
          backgroundColor={colors.backgroundLight}
          borderTopRightRadius={radius.l}
          borderTopLeftRadius={radius.l}
        >
          <Block padding={spacings.xs} width="50%">
            <CubeSchema
              width={formik.values.width}
              height={formik.values.height}
              long={formik.values.long}
            />
          </Block>
        </Block>
        <Modal.Item.Wrapper>
          <Block marginBottom={spacings.s}>
            <H3>{polyglot.t("moving_simulator.custom_element")}</H3>
          </Block>
          <FormControl name="long" label="Longueur">
            <Slider
              persisentThumb
              min={5}
              max={500}
              step={5}
              ticks={[
                `${polyglot.toDistance(MIN, { small: true })}`,
                `${polyglot.toDistance(MAX, { small: true })}`,
              ]}
              value={formik.values.long}
              onChange={(v) => {
                formik.setFieldValue("long", v);
              }}
              formatValue={(v) => `${polyglot.toDistance(v, { small: true })}`}
            />
          </FormControl>
          <FormControl name="width" label="Largeur">
            <Slider
              persisentThumb
              min={5}
              max={500}
              step={5}
              ticks={[
                `${polyglot.toDistance(MIN, { small: true })}`,
                `${polyglot.toDistance(MAX, { small: true })}`,
              ]}
              value={formik.values.width}
              onChange={(v) => {
                formik.setFieldValue("width", v);
              }}
              formatValue={(v) => `${polyglot.toDistance(v, { small: true })}`}
            />
          </FormControl>
          <FormControl name="height" label="Hauteur">
            <Slider
              persisentThumb
              min={5}
              max={500}
              step={5}
              ticks={[
                `${polyglot.toDistance(MIN, { small: true })}`,
                `${polyglot.toDistance(MAX, { small: true })}`,
              ]}
              value={formik.values.height}
              onChange={(v) => {
                formik.setFieldValue("height", v);
              }}
              formatValue={(v) => `${polyglot.toDistance(v, { small: true })}`}
            />
          </FormControl>
        </Modal.Item.Wrapper>
        <Modal.Item.Footer column>
          <Button.Large block type="submit">
            {polyglot.t("common.save")}
          </Button.Large>
          <Button.Large
            block
            kind={BUTTON.KIND.SECONDARY}
            onClick={handleClose}
          >
            {polyglot.t("common.close")}
          </Button.Large>
        </Modal.Item.Footer>
      </form>
    </Modal.Small>
  );
};
export default NewObjectModal;
