import CESU from "@/assets/images/svg-icons/Badges/Payments/CESU.svg";
import { colors, sizes, spacings } from "@/assets/themes";
import { Block, Body14, Caption, Icon, List, Shape } from "@/components";
import { SHAPE } from "@/components/Styles/variants";
import polyglot from "@/utils/polyglot";
import {
  getPaymentMethodBadge,
  getPaymentMethodConfig,
} from "../common/payment-methods/utils";

const JobberPaymentInformations = ({
  payment_methods = ["visa_mastercard"],
}) => (
  <List.Item
    divider={false}
    LeftComponent={() => (
      <Shape.Medium
        backgroundColor={colors.primaryLight}
        color={colors.primary}
        shape={SHAPE.SHAPE.CIRCLE}
      >
        <Icon.Large name="shield-half" />
      </Shape.Medium>
    )}
  >
    <Body14>
      {polyglot.t("jobber_profile.platform_outside_payment_tips")}
    </Body14>

    <Block marginTop={spacings.xs}>
      <Block display="flex" alignItems="wrap" gap={spacings.xxs}>
        {[
          ...payment_methods.map((m) => ({
            key: m,
            src: getPaymentMethodBadge(m),
            alt: getPaymentMethodConfig(m).label,
          })),
          ...(polyglot.country === "fr"
            ? [{ key: "cesu", label: polyglot.t("common.cesu"), src: CESU }]
            : []),
        ].map((method) => (
          <img
            key={`profile-payment-method-${method.key}`}
            src={method.src}
            alt={method.label}
            css={`
              height: ${sizes.size24};
              width: auto;
            `}
          />
        ))}
      </Block>
      <Block marginTop={spacings.s}>
        <Caption strong color={colors.muted}>
          {polyglot.t("jobber_profile.pay_4x_paypal")}
        </Caption>
      </Block>
    </Block>
  </List.Item>
);

export default JobberPaymentInformations;
