import { borderWidth, colors, radius, sizes, spacings } from "@/assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Body16,
  Caption,
  ConditionnalWrapper,
  Rating,
  ReadMore,
} from "@/components";
import { CardBase } from "@/components/Styles/Base";
import UserPreview from "@/pages-admin/UserPreview";
import { CUSTOMER } from "@/pages-admin/UserPreview/config";
import polyglot from "@/utils/polyglot";
import JobberProfileGallery from "./JobberProfileGallery";
import { getCustomerSince } from "./utils";

const RateItem = ({
  id,
  customer,
  job_title,
  rate,
  onClick,
  comment,
  created_at,
  isCard,
  photos,
  numberOfLines,
}) => (
  <ConditionnalWrapper
    condition={isCard}
    wrapper={(children) => (
      <CardBase
        flat
        type="button"
        onClick={onClick}
        css={`
          border: solid ${borderWidth.s} ${colors.border};
          height: 100%;
        `}
      >
        <Block paddingX={spacings.m} paddingY={spacings.s} height="100%">
          {children}
        </Block>
      </CardBase>
    )}
  >
    <Block
      display="flex"
      flexDirection="column"
      height={isCard ? "100%" : undefined}
    >
      <Block spaceY={spacings.s} marginBottom={spacings.xs}>
        <UserPreview id={customer.id} kind={CUSTOMER}>
          <Block display="flex" gap={spacings.sm}>
            <Avatar
              size={sizes.size48}
              name={customer.first_name}
              src={customer.avatar}
            />
            <Block>
              <Body16 strong>{customer.first_name}</Body16>
              <Body14 color={colors.muted}>
                {getCustomerSince(customer.member_since).label}
              </Body14>
            </Block>
          </Block>
        </UserPreview>
        <Block display="flex" alignItems="center" flexWrap="wrap">
          <Rating rate={rate} />
          &nbsp;
          <Caption strong numberOfLines={1}>
            {created_at}
          </Caption>
          {!!customer.regular_customer && (
            <Caption numberOfLines={1} strong color={colors.muted}>
              &nbsp;•&nbsp;{polyglot.t("jobber_profile.repeater")}
            </Caption>
          )}
        </Block>
      </Block>
      <Block
        spaceY={spacings.xs}
        flex={isCard ? "1" : undefined}
        display="flex"
        flexDirection="column"
        justifyContent={isCard ? "space-between" : undefined}
        css={`
          pointer-events: ${isCard ? "none" : "auto"};
        `}
      >
        <Block>
          {photos?.length > 0 && (
            <Block paddingBottom={isCard ? spacings.xs : spacings.s}>
              <JobberProfileGallery
                rates={[
                  {
                    id,
                    customer,
                    job_title,
                    rate,
                    comment,
                    created_at,
                    photos,
                  },
                ]}
                onClick={onClick}
              >
                {photos.map(({ thumbnail, large }) => (
                  <img
                    key={`${thumbnail}-photos-item-rate`}
                    src={thumbnail}
                    alt={`${job_title} photos`}
                    srcSet={large}
                    style={{
                      objectFit: "cover",
                      height: isCard ? sizes.size32 : sizes.size96,
                      width: isCard ? sizes.size32 : sizes.size96,
                      borderRadius: isCard ? radius.s : radius.m,
                    }}
                  />
                ))}
              </JobberProfileGallery>
            </Block>
          )}
          <Body16>
            {numberOfLines ? (
              <ReadMore
                numberOfLines={numberOfLines}
                css={`
                  button {
                    color: ${colors.body}!important;
                    pointer-events: ${isCard ? "none" : "auto"};
                  }
                `}
              >
                {comment}
              </ReadMore>
            ) : (
              <span
                css={`
                word-break: break-word;
                white-space: break-spaces;
              `}
              >
                {comment}
              </span>
            )}
          </Body16>
        </Block>
        <Body14 color={colors.muted} numberOfLines={isCard ? 1 : undefined}>
          {job_title}
        </Body14>
      </Block>
    </Block>
  </ConditionnalWrapper>
);

export default RateItem;
